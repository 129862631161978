import type { SiteResponse } from '@on3/api';
import { On3IconForum } from '@on3/icons';
import { getAssetUrl } from '@on3/ui-lib/utils/images';
import { slugify } from '@on3/ui-lib/utils/slugify';
import clsx from 'clsx';
import { TeamAvatar } from 'components/Image/TeamAvatar';
import Link from 'next/link';

import styles from './FanSiteItem.module.scss';

export interface IFanSiteItem {
  data?: SiteResponse;
  isFavoriteTeam?: boolean;
}

export const FanSiteItem = ({ data, isFavoriteTeam }: IFanSiteItem) => {
  const { organization, url = '/', siteName, forum, key } = data || {};
  const isSoftballAmerica = key === 401;
  const name = isSoftballAmerica ? 'Softball' : organization?.name ?? 'default';
  const siteUrl = url?.endsWith('/') ? url : url + '/' || '/';
  const avatarPath = slugify(name);

  return (
    <li
      className={clsx(styles.fanitem, {
        [styles.favoriteTeam]: isFavoriteTeam,
      })}
    >
      <Link className={styles.logo} href={siteUrl}>
        <TeamAvatar
          height={30}
          src={
            getAssetUrl(organization?.defaultAssetRes) ??
            `https://on3static.com/cdn-cgi/image/height=30,width=30/sites/${avatarPath}-icon-full.svg?v=27`
          }
          width={30}
        />
      </Link>
      <Link className={styles.fanWrapper} href={siteUrl}>
        <span className={styles.fanText}>{siteName}</span>
        <span className={styles.team}>{name}</span>
      </Link>
      {forum?.url && (
        <a className={styles.forum} href={forum?.url}>
          <On3IconForum height={14} width={14} />
          <span className="sr-only">Go to Boards</span>
        </a>
      )}
    </li>
  );
};
